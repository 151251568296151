body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.height_100 {
  height: 100%;
}

.width_100 {
  width: 100%;
}

.width_30 {
  width: 30%;
}

.header-container {
  width: 100%;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.main-logo {
  width: 50px;
  padding: 8px 10px;
}

.menu-icon {
  padding: 0px 10px;
  font-weight: bolder;
  cursor: pointer;
  color: #736363 !important;
  font-size: 40px !important;
}

.main-profile-wrap {
}

.main-profile {
  width: 40px;
  padding: 5px;
  margin: 5px 10px;
  border-radius: 50% !important;
  border: 2px solid #ccc;
}

.custom-contained-btn {
  border-radius: 0px !important;
  padding: 7px 25px !important;
  /* background-color: #1f618d !important; */
  background-color: #cfbe25 !important;
  color: #ffffffc0 !important;
}

.custom-outlined-btn {
  border-radius: 0px !important;
  padding: 7px 25px !important;
  color: #1f618d !important;
  border-color: #1f618d !important;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
  .MuiAutocomplete-input {
  padding: 5px 4px !important;
}

.list-section {
  width: 100%;
  min-height: 300px;
  background-color: #73715a;
  border-radius: 5px;
}

.duration-entry-txt-wrap {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 5px 20px;
  color: #fff;
}

.scq-user-txt {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 5px 20px;
  color: #fff;
}

.field-txt-wrap {
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.field-txt {
  color: #000;
  background-color: #fff;
  width: 90%;
  padding: 20px 10px;
  border-radius: 10px !important;
}

.create-question-txt {
  width: 100%;
  text-align: center;
}

.class-txt {
  background-color: #dfce44 !important;
}

/* .css-10k6dy2-MuiInputBase-root-MuiOutlinedInput-root {
  border: 10px solid #dfce44 !important;
  border-radius: 0px !important;
} */

.class-img {
  background-color: #dfce44;
}

.txt-img-field .question-textfield {
  border: 10px solid #dfce44 !important;
  min-height: 120px;
  border-radius: 0px !important;
}
.txt-img-field .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  border: 10px solid #dfce44 !important;
  min-height: 120px;
}

.txt-img-field .css-10k6dy2-MuiInputBase-root-MuiOutlinedInput-root {
  border: 10px solid #dfce44 !important;
  min-height: 120px;
  border-radius: 0px !important;
}

.txt-img-field .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  background: none !important;
}

.txt-img-field .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.question-lable-txt {
  padding: 10px 0px;
  background-color: #dfce44;
}

.image-field {
  background-color: #dfce44 !important;
  padding: 20px;
}

.image-field .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  /* border: 10px solid #dfce44 !important; */
  padding: 20px;
  background: #fff;
}

.image-field .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid #ccc !important;
}

.question-choicetype-txt {
  background-color: #dfce44;
}
.question-choicetype-txt-default {
  background-color: #ffffff;
}

.preview-wrap {
  width: 100%;
}

.question-wrap {
  width: 100%;
  min-height: 200px;
  background-color: #dfce44;
  margin: 10px 0px;
  border-radius: 8px;
}

.preview-checkbox-field .css-i4bv87-MuiSvgIcon-root {
  /* background-color: #fff !important; */
  color: #2e2d66;
  font-weight: bolder !important;
}

.preview-checkbox-field .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #fff;
}

.preview-checkbox-field
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  font-weight: bolder !important;
}

.selected-checkbox input {
  border: 3px solid #2e2d66 !important;
}

.selected-checkbox
  .css-ny8a2x-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  border: 3px solid #2e2d66 !important;
}

.delete-view-btn Button {
  background-color: #2e2d66 !important;
  color: #fff !important;
  border-radius: 40px !important;
  font-size: 13px !important;
  margin: 10px 0px;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
  .MuiAutocomplete-input {
  padding: 4px 4px !important;
  font-size: 14px !important;
  font-weight: bolder;
  color: #a9a9a9;
}

.css-1ut9cfl-MuiTypography-root {
  padding: 10px !important;
  font-size: 14px !important;
  font-weight: bolder;
  color: #353434;
}

.css-ny8a2x-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 14px !important;
  font-weight: bolder !important;
  color: #a9a9a9 !important;
  max-height: 14px !important;
}
.MuiDropzoneArea-root {
  min-height: 60px !important;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MuiDropzoneArea-icon {
  display: none !important;
}
.MuiDropzoneArea-text {
  margin: 0px !important;
}

.home {
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  h1 {
    color: #ff6347;
  }
  h2 {
    color: #ff4500;
  }
  p {
    font-size: 18px;
    line-height: 1.6;
  }
  .btn-download {
    display: inline-block;
    margin: 10px;
    padding: 15px 25px;
    background-color: #ff4500;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
  }
  .btn-download:hover {
    background-color: #ff6347;
  }
  .content-section {
    margin: 20px 0;
  }
}
.MuiIconButton-colorSecondary {
  padding: 0 !important;
}
